$journal-latest-issue--border-color: $t-a-palette-default-greyscale-800;
$journal-latest-issue--image-desktop-width: 115px;
$journal-latest-issue--image-mobile-width: 60px;
$journal-latest-issue--pipe-color: $t-a-palette-default-blue-light;
$journal-latest-issue--spacing-xs: spacing(4);
$journal-latest-issue--spacing-s: spacing(8);
$journal-latest-issue--spacing-md: spacing(16);
$journal-latest-issue--spacing-l: spacing(24);
$journal-latest-issue--spacing-xl: spacing(32);

.app-journal-latest-issue {
	.app-journal-homepage & {
		background-color: #eff6fb;
	}
}

.app-journal-latest-issue__container {
	.app-journal-homepage & {
		@include u-container;
	}
}

.app-journal-latest-issue__inner {
	display: grid;
	grid-template-columns: auto minmax(0, 1fr) auto;
	border-top: 1px solid $journal-latest-issue--border-color;
	border-bottom: 1px solid $journal-latest-issue--border-color;
	padding: $journal-latest-issue--spacing-md 0;
	overflow: hidden;
	line-height: $t-a-typography-line-height-tight;

	.app-journal-homepage & {
		border: none;

		@include from-tablet {
			padding: 0;
		}
	}
}

.app-journal-latest-issue__heading {
	font-size: clamp($t-a-typography-font-size-default, 4vw, $t-a-typography-font-size-xl);
	margin-bottom: $journal-latest-issue--spacing-xs;
}

.app-journal-latest-issue--journalPage .app-journal-latest-issue__heading {
	font-size: clamp($t-a-typography-font-size-s, 4vw, $t-a-typography-font-size-l);
	margin-bottom: $journal-latest-issue--spacing-s;
}

.app-journal-latest-issue__date,
.app-journal-latest-issue__volume {
	font-size: clamp($t-a-typography-font-size-xs, 4vw, $t-a-typography-font-size-s);
}

.app-journal-latest-issue__volume:before {
	content: "|";
	display: inline-block;
	color: $journal-latest-issue--pipe-color;
	padding: 0 $journal-latest-issue--spacing-s;
}

.app-journal-latest-issue__cover {
	grid-area: 1 / 1 / 3 / 2;
	width: $journal-latest-issue--image-mobile-width;
	margin: 0;
	margin-right: $journal-latest-issue--spacing-s;
}

.app-journal-latest-issue--journalPage .app-journal-latest-issue__cover {
	width: 88px;
	margin-right: 12px;
}
.app-journal-latest-issue__cover img {
	width: 100%;
	height: auto;
}

.app-journal-latest-issue__contents {
	grid-area: 1 / 2 / 2 / 3;
}

.app-journal-latest-issue__title {
	font-size: clamp($t-a-typography-font-size-s, 4vw, $t-a-typography-font-size-md);
	margin: $journal-latest-issue--spacing-s 0 $journal-latest-issue--spacing-md;
}

.app-journal-latest-issue--journalPage .app-journal-latest-issue__title {
	grid-area: 1 / 2 / 2 / 4;
	font-size: $t-a-typography-font-size-xs;
	font-weight: $t-a-typography-font-weight-bold;
	margin: 0;
}

.app-journal-latest-issue__supplement {
	font-size: $t-a-typography-font-size-xs;
	font-weight: $t-a-typography-font-weight-bold;
	margin-top: $journal-latest-issue--spacing-md;
}

.app-journal-latest-issue__info {
	grid-area: 3 / 1 / 4 / 3;
	font-size: $t-a-typography-font-size-xs;
	line-height: 24px;
}

.app-journal-latest-issue--journalPage .app-journal-latest-issue__info {
	grid-area: 2 / 1 / 4 / 3;
	display: grid;
	grid-template-columns: 100px auto;
	grid-template-rows: auto auto;
	grid-row-gap: $journal-latest-issue--spacing-l;
}

.app-journal-latest-issue__note + .app-journal-latest-issue__details {
	margin-top: $journal-latest-issue--spacing-md;
}

.app-show-more-show-less + .app-journal-latest-issue__details {
	margin-top: spacing(24);
}

.app-journal-latest-issue__details {
	@include u-list-reset;
	padding: 0;
	margin: 0;
}

.app-journal-latest-issue__details-item {
	display: inline-block;
	position: relative;
	margin-right: 10px;

	a {
		color: currentColor;
	}

	&:last-of-type {
		margin-right: 0;
	}

	&:first-child:nth-last-child(2) ~ &,
	&:first-child:nth-last-child(3) ~ & {
		line-height: 1;
	}

	&:first-child:nth-last-child(2) ~ & {
		border-left: 2px solid $journal-latest-issue--pipe-color;
		border-right: none;
		padding-left: 13px;
	}

	// This logic is due to the need to have the pipe/border-right of the 3rd item in the same line as the 2nd in mobile
	&:nth-of-type(2):has(+ &) {
		border-left: 2px solid $journal-latest-issue--pipe-color;
		border-right: 2px solid $journal-latest-issue--pipe-color;
		padding: 0 13px;
	}
}

.app-journal-latest-issue__note {
	font-size: $t-a-typography-font-size-xs;
	line-height: $t-a-typography-line-height-base;
}

.app-journal-latest-issue__note.show-more-show-less {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.app-journal-latest-issue .app-show-more-show-less {
	margin-top: $journal-latest-issue--spacing-s;

	&__icon {
		width: 1rem;
		height: 1rem;
		margin-right: 6px;
	}
}

.app-journal-latest-issue__note.show-more-show-less ~ .app-show-more-show-less span {
	font-size: $t-a-typography-font-size-xs;
}

.app-journal-latest-issue__details + .app-journal-latest-issue__editors {
	margin-top: $journal-latest-issue--spacing-s;
}

.app-journal-latest-issue__editors {
	font-weight: $t-a-typography-font-weight-bold;
	font-size: $t-a-typography-font-size-xs;
}

.app-journal-latest-issue__editors-list {
	@include u-list-reset;
	display: inline;
}

.app-journal-latest-issue__editors dt,
.app-journal-latest-issue__editors dd {
	display: inline;
}

.app-journal-latest-issue__editors dt {
	margin-right: $journal-latest-issue--spacing-xs;
}

.app-journal-latest-issue__editor {
	font-weight: $t-a-typography-font-weight-normal;
	display: inline;
}

.app-journal-latest-issue__editors:last-child {
	margin-bottom: 0;
}

.app-journal-latest-issue__svg {
	fill: $t-a-palette-default-blue-medium-600;
	width: 22px;
	height: 22px;
	vertical-align: middle;
	margin-right: $journal-latest-issue--spacing-s;
}

.app-journal-latest-issue__link {
	margin-top: $t-a-space-absolute-400;
	text-align: center;

	a {
		width: 100%;
		line-height: 1.8;
	}

	@include from-tablet {
		margin: 0;
	}
}

.app-journal-latest-issue--journalPage .app-journal-latest-issue__link {
	grid-area: 3 / 1 / 3 / 4;
}

*:not(.app-journal-latest-issue__editors) ~ .app-journal-latest-issue__supplement-file {
	margin-top: $journal-latest-issue--spacing-md;
}

.app-journal-latest-issue__supplement-file {
	display: block;
	color: currentColor;
}

@include from-wide-tablet {
	.app-journal-latest-issue {
		padding: $journal-latest-issue--spacing-xl 0;
	}

	.app-journal-latest-issue__cover,
	.app-journal-latest-issue--journalPage .app-journal-latest-issue__cover {
		width: 115px;
		margin-bottom: 0;
		margin-right: $journal-latest-issue--spacing-md;
	}

	.app-journal-latest-issue__note.show-more-show-less.app-show-more-show-less--expanded {
		display: initial;
	}

	.u-list-reset.app-journal-latest-issue__details {
		margin-top: $journal-latest-issue--spacing-md;
	}

	.app-journal-latest-issue__info {
		grid-area: 2 / 2 / 4 / 3;
		align-self: center;
		margin-left: 0;
	}

	.app-journal-latest-issue--journalPage .app-journal-latest-issue__info {
		grid-area: 2 / 1 / 3 / 3;
		grid-template-columns: 131px auto;
		display: flex;
		flex-direction: column;
		row-gap: $journal-latest-issue--spacing-l;
	}

	.app-journal-latest-issue--journalPage .app-journal-latest-issue__title,
	.app-journal-latest-issue--journalPage .app-journal-latest-issue__link {
		width: calc(100% - 130px);
		align-self: flex-end;
	}

	.app-journal-latest-issue__link {
		text-align: left;

		a {
			width: 335px;
		}
	}
}
